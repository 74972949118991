@tailwind base;
@tailwind components;
@tailwind utilities;

/* styles.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-image {
  max-width: 100px;
  margin-top: 10px;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #4caf50; /* Green */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

input, textarea {
  transition: border-color 0.3s;
}

input:focus, textarea:focus {
  border-color: #4caf50; /* Green focus effect */
  outline: none;
}

button {
  transition: background-color 0.3s;
}